
import Vue from "vue";
import {
  countyMapMutations,
  countyMapActions,
  countyMapGetters,
  countyMapState
} from "@/store/modules/county";
import { tableColumns } from "./ListCountiesTableColumns";
import { ITableWrapperColumns } from "@/types";
import { tiers } from "@/helpers/selectOptions";
import * as types from "@/store/mutation-types";

interface CommunicationsData {
  loading: boolean;
  tableColumns: ITableWrapperColumns;
  selectedDocuments: any;
  bulkMenuActions: { title: string; disabled: boolean }[];
  tableActions: string[];
  filters: any[];
  tableRowActions: any;
  errorMessage: string;
  countyId: string;
  bindingMessage: string;
  prohibitBinding: string;
  countyCode: string;
  loadingText: string;
}

export default Vue.extend({
  name: "listCounties",
  components: {
    AddOrEditCounty: () => import("./AddOrEditCounty.vue"),
    CountiesUploader: () => import("./CountiesUploader.vue"),
    Exporter: () => import("@/components/Exporter/Exporter.vue")
  },
  data(): CommunicationsData {
    return {
      loading: false,
      tableColumns: tableColumns,
      selectedDocuments: [],
      errorMessage: "",
      countyId: "",
      bindingMessage: "",
      prohibitBinding: "",
      countyCode: "",
      bulkMenuActions: [
        { title: "Prohibit Selected", disabled: false },
        { title: "Delete Counties", disabled: false }
      ],
      tableActions: [
        "Upload Counties",
        "Export Counties",
        "All Activities"
      ].sort(),
      filters: [
        {
          field: "tier",
          title: "Tier",
          options: tiers
        },
        {
          field: "prohibitBinding",
          title: "Binding Prohibited",
          options: [
            { label: "Yes", value: "true" },
            { label: "No", value: "false" }
          ]
        }
      ],
      tableRowActions: [
        { label: "Edit", value: "edit", faIcon: "edit" },
        {
          label: (row: any) =>
            row.prohibitBinding ? "Allow Binding" : "Prohibit Binding",
          value: "toggle-prohibition",
          faIcon: (row: any) =>
            row.prohibitBinding ? "thumbs-up" : "thumbs-down"
        },
        {
          label: "Delete",
          value: "delete",
          faIcon: "trash-alt",
          class: "bg-red-400 text-white hover:text-white hover:bg-red-500"
        },
        {
          label: " Activities",
          value: "activities",
          faIcon: "book-open"
        }
      ],
      loadingText: ""
    };
  },
  methods: {
    ...countyMapActions([
      "getCounties",
      "deleteCounty",
      "createCounty",
      "updateCounty",
      "prohibitBindingForCounty",
      "prohibitBindingForCounties",
      "toggleBulkCountyBindingProhibition",
      "deleteCountiesByCountyCode",
      "cancelProhibitBindingForCounty"
    ]),

    ...countyMapMutations([types.SET_STATE, types.SET_EDIT, types.SET_ITEM]),
    onToolbarItemClick(event: string) {
      switch (event) {
        case "add":
          this.addCountyActionHandler();
          break;
        case "upload":
          this.uploadCountiesActionHandler();
          break;
        case "export":
          this.$modal.show("exportCountyModal");
          break;
      }
    },
    tableActionHandler(action: string) {
      switch (action) {
        case "Upload Counties":
          return this.uploadCountiesActionHandler();
        case "Export Counties":
          this.$modal.show("exportCountyModal");
          break;
        case "All Activities":
          this.$router.push(`/admin/counties/activities`).catch(() => {});
          break;
      }
    },
    async bulkActionHandler(action: string) {
      switch (action) {
        case "Prohibit Selected": {
          try {
            const prohibitIds = this.selectedDocuments.map(
              (doc: any) => doc.countyCode
            );
            await this.prohibitBindingForCounties(prohibitIds);
            this.$appNotifySuccess("Selected Counties Prohibited");
          } catch (error) {
            this.errorMessage = error.message;
            this.$bugSnagClient.notify(error);
          }
          break;
        }
        case "Delete Counties": {
          try {
            const prohibitIds = this.selectedDocuments.map(
              (doc: any) => doc.countyCode
            );
            await this.deleteCountiesByCountyCode(prohibitIds);
            this.$appNotifySuccess("Selected Counties Deleted");
            this.fetchPageData(
              this.$store.state.list_view_module["filterQuery"] || {}
            );
          } catch (error) {
            this.errorMessage = error.message;
            this.$bugSnagClient.notify(error);
          }
          break;
        }
        default:
          break;
      }
    },
    async fetchPageData(queryObject: any): Promise<void> {
      try {
        this.loading = true;
        this.loadingText = "Loading counties. Please wait...";
        this.$emit("scrollTop");
        await this.getCounties({ query: queryObject });
      } catch (error) {
        this.errorMessage = "Error fetching communications. Try again later";
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },
    async bulkProhibitionHandler() {
      try {
        await this.confirmProhibitionBulkToggling();
      } catch (error) {
        if (error !== "cancel") this.errorMessage = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    uploadSuccess(result: any): void {
      this.$appNotifySuccess("Uploaded counties");
      this[types.SET_ITEM](result.response);
    },
    uploadError(error: any) {
      this.errorMessage = error.response.message;
    },
    addCountyActionHandler(): void {
      this.SET_EDIT({} as any);
      this.$modal.show("countyEditorModal");
    },
    uploadCountiesActionHandler(): void {
      this.$modal.show("countyUploaderModal");
    },
    viewCallback(dataReceived: any): void {
      this.SET_EDIT(dataReceived);
      this.$modal.show("countyEditorModal");
    },
    bulkProhibitionTitle(): string {
      return !this.hasProhibitedCounty
        ? "Prohibit All Counties"
        : "Allow Quoting in All Counties";
    },
    async confirmRemove(): Promise<void> {
      this.$modal.show("countiesDeleteModal");
    },
    async confirmProhibitionToggling(payload: {
      prohibitBinding: boolean;
      countyName: string;
    }) {
      const { countyName, prohibitBinding } = payload;
      const prohibitMessage = `This will prohibit binding on ${countyName} county`;
      const allowMessage = `This will permit binding on ${countyName} county`;
      this.bindingMessage = prohibitBinding ? allowMessage : prohibitMessage;
      this.$modal.show("countiesProhibitionTogglingModal");
    },
    async confirmProhibitionBulkToggling() {
      const prohibitMessage = `This will prohibit binding on all counties`;
      const allowMessage = `This will permit binding on all counties`;
      this.bindingMessage = this.hasProhibitedCounty
        ? allowMessage
        : prohibitMessage;
      this.$modal.show("countiesBulkProhibitionTogglingModal");
    },
    async handleResponseAction(payload: {
      name: string;
      data: Record<string, any>;
    }) {
      switch (payload.name) {
        case "toggle-prohibition":
          try {
            this.loading = true;
            this.prohibitBinding = payload.data.prohibitBinding;
            this.countyCode = payload.data.countyCode;
            await this.confirmProhibitionToggling(payload.data as any);
          } catch (error) {
            if (error !== "cancel") {
              //do nothing
            }
            this.$bugSnagClient.notify(error);
          } finally {
            this.loading = false;
          }
          break;
        case "delete":
          try {
            this.loading = true;
            const { _id } = payload.data;
            this.countyId = _id;
            await this.confirmRemove();
          } catch (error) {
            this.$appNotifyError("Sorry, could not delete county");
            this.$bugSnagClient.notify(error);
          } finally {
            this.loading = false;
          }
          break;
        case "edit":
          this.viewCallback(payload.data);
          break;
        case "activities":
          this.$router
            .push(`/counties/activities/${payload.data._id}`)
            .catch(() => {});
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },
    async deleteOneCounty() {
      await this.deleteCounty(this.countyId);
      this.$modal.hide("countiesDeleteModal");
      this.$appNotifySuccess("County deleted");
    },
    async toggleProhibition() {
      if (this.prohibitBinding) {
        await this.cancelProhibitBindingForCounty(this.countyCode);
      } else {
        await this.prohibitBindingForCounty(this.countyCode);
      }
      this.$modal.hide("countiesProhibitionTogglingModal");
      this.$appNotifySuccess("Binding Prohibited Successfully");
    },
    async toggleBulkProhibition() {
      await this.toggleBulkCountyBindingProhibition({
        prohibitBinding: !this.hasProhibitedCounty
      });
      this.fetchPageData({});
      const prohibitionToggleText = this.hasProhibitedCounty
        ? "Allowed Binding For All Counties"
        : "Prohibited Binding For All Counties";
      this.$modal.hide("countiesBulkProhibitionTogglingModal");
      const successMessage = `Successfully ${prohibitionToggleText}`;
      this.$appNotifySuccess(successMessage);
    },
    async handleListViewUpdate() {
      this.$modal.hide("countyEditorModal");
      await this.fetchPageData(
        this.$store.state.list_view_module["filterQuery"] || {}
      );
    }
  },
  computed: {
    ...countyMapGetters([
      "getTotalRecords",
      "getMaxQuerySize",
      "hasProhibitedCounty"
    ]),
    ...countyMapState(["links", "counties", "makingApiRequest", "editing"]),
    modalTitle(): string {
      const isNew = this.editing && this.editing._id.length >= 12;
      return isNew ? "Edit County" : "Add County";
    }
  }
});
